import { debounce } from "./debounce";

type WindowPort = {
  width: number;
  height: number;
};

type ViewPort = {
  width: number;
  height: number;
  top: number;
};

interface ITrackItem {
  windowPort: WindowPort;
  viewPort: ViewPort;
  cords: { x: number; y: number };
}

export default class Bocho {
  viewPort: ViewPort;
  windowPort: WindowPort;
  mouseX: number;
  mouseY: number;
  history: ITrackItem[];
  purge: Function;

  constructor() {
    this.viewPort = {
      width: window.innerWidth,
      height: window.innerHeight,
      top: window.document.scrollingElement.scrollTop,
    };
    this.windowPort = { width: window.outerWidth, height: window.outerHeight };
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.history = [];
    this.purge = debounce(
      () => {
        console.log(this.history);
        this.history = [];
      },
      500,
      false
    );
    this.init();
  }

  save(item: ITrackItem) {
    this.history.push(item);
    this.purge();
  }

  generateTrackItem() {
    const item: ITrackItem = {
      windowPort: this.windowPort,
      viewPort: this.viewPort,
      cords: { x: this.mouseX, y: this.mouseY },
    };

    this.save(item);
  }

  handleMouseMove({ clientX, clientY }) {
    this.mouseX = clientX;
    this.mouseY = clientY;

    const log = this.generateTrackItem();
  }

  handleResize() {
    this.viewPort = {
      width: window.innerWidth,
      height: window.innerHeight,
      top: window.document.scrollingElement.scrollTop,
    };
    this.windowPort = { width: window.outerWidth, height: window.outerHeight };
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  init() {
    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("resize", this.handleResize, { passive: true });
  }

  destroy() {
    window.removeEventListener("mousemove", this.handleMouseMove);
    window.removeEventListener("resize", this.handleResize);
  }
}
