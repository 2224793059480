import Magnetic from "./utils/magnetic";
import Bocho from "./utils/bocho";
import { transform } from "popmotion";

const elem = document.getElementById("title");

const interWght = transform.interpolate([100, 0], [100, 700]);
const interXY = transform.interpolate([100, 0], [300, 700]);

new Magnetic({
  element: elem,
  radius: 500,
  cb: (dist) => {
    const weight = interWght(dist);

    elem.style.cssText = `font-variation-settings: "wght" ${weight};`;
  },
});

const bocho = new Bocho();

window.document.addEventListener("click", () => {
  console.log(bocho.history);
});
