/**
 * Debounce a function to limit the number of times it can be called in a certain time period.
 *
 * @export
 * @param {Function} func The function to debounce.
 * @param {number} wait The minimum amount of time, in milliseconds, that must pass between calls to `func`.
 * @param {boolean} immediate Whether or not to immediately call `func` if the wait time has already been elapsed.
 * @return {Function} A debounced version of `func`.
 */
export function debounce(func: Function, wait: number, immediate: boolean) {
  let timeout: number;
  return function (...args: any[]) {
    const context = this;
    const later: TimerHandler = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
