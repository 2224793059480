interface IOptions {
  element: HTMLElement;
  radius: number;
  cb: (arg0: any) => void;
}

export default class Magnetic {
  mouseX: number;
  mouseY: number;
  distance: number;
  cb: (arg0: any) => void;
  element: HTMLElement;
  radius: number;

  constructor(options: IOptions) {
    this.element = options.element;
    this.radius = options.radius;
    this.cb = options.cb;
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.distance = 0;
    this.init();
  }

  get elementPosition() {
    const elementCoordinate = this.element.getBoundingClientRect();
    return {
      left: elementCoordinate.left + document.body.scrollLeft,
      right: elementCoordinate.left + this.element.offsetWidth,
      top: elementCoordinate.top + document.body.scrollTop,
      bottom: elementCoordinate.top + this.element.offsetHeight,
    };
  }

  get intersectX() {
    const { left, right } = this.elementPosition;
    return Math.min(this.mouseX, right) >= Math.max(this.mouseX, left);
  }

  get intersectY() {
    const { top, bottom } = this.elementPosition;
    return Math.min(this.mouseY, bottom) >= Math.max(this.mouseY, top);
  }

  get mouseMoveRange() {
    const { left, right, top, bottom } = this.elementPosition;
    const coordinatX = this.intersectX
      ? this.mouseX
      : right < this.mouseX
      ? right
      : left;
    const coordinatY = this.intersectY
      ? this.mouseY
      : bottom < this.mouseY
      ? bottom
      : top;
    return {
      x: coordinatX,
      y: coordinatY,
    };
  }

  getHypotenuse(sideX, sideY) {
    return (sideX ** 2 + sideY ** 2) ** (1 / 2);
  }

  getPercent(maxValue, currentValue) {
    return Math.floor((Math.abs(currentValue) / maxValue) * 100);
  }

  getDiagonalDistance() {
    const distanceX = this.mouseMoveRange.x - this.mouseX;
    const distanceY = this.mouseMoveRange.y - this.mouseY;
    const hypotenuse = this.getHypotenuse(distanceX, distanceY);
    return Math.floor(hypotenuse);
  }

  handleMouseMove({ clientX, clientY }) {
    this.mouseX = clientX;
    this.mouseY = clientY;

    if (this.isWithinRange()) {
      this.distance = this.getResultingDistance();
    } else {
      this.distance = 100;
    }
    this.cb(this.distance);
  }

  getResultingDistance() {
    const resultingDistance = this.getPercent(
      this.radius,
      this.getDiagonalDistance()
    );

    return resultingDistance;
  }

  isWithinRange() {
    return this.getDiagonalDistance() < this.radius;
  }

  init() {
    window.addEventListener("mousemove", this.handleMouseMove);
  }

  destroy() {
    window.removeEventListener("mousemove", this.handleMouseMove);
  }
}
